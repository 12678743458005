import React, { useEffect, useRef, useState } from "react";
import { Link, graphql } from "gatsby";
import Helmet from "react-helmet";
import useBackgroundColor from "../zustand/useBackgroundColor";
import Image from "gatsby-image";
import SEO from "../components/seo";
import Slider from "react-slick";
import Scrollbar from "smooth-scrollbar";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useNewsletter from "../zustand/useNewsletter";
import moment from "moment";
import _ from "lodash";
import Layout from "../layouts";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import useBlogFilter from "../zustand/useBlogFilter";
import useReelState from "../zustand/useReelState";
import axios from "axios";

/* Nome Page
============================================================================= */

const BlogPage = ({ data, location }) => {
    // Config
    const { setIsDark } = useBackgroundColor();
    const { nodes: blogs } = data.allSanityBlog;
    const { nodes: tags } = data.allSanityTag;
    const { setActiveTag, activeTag } = useBlogFilter();
    const { canOpenReel, setCanOpenReel } = useReelState();
    const [newsletterOpen, setNewsletterOpen] = useState(false);

    useEffect(() => {
        setCanOpenReel(false);
        setIsDark(true);
        setActiveTag("ALL");
        ScrollTrigger.getAll().forEach((el) => {
            el.scroll(0);
            el.refresh();
            el.disable();
        });
    }, []);

    // Render
    return (
        <Layout location={location}>
            <Helmet>
                <title>
                    Our Blog - Thoughts, Insights & Tips | MITP Agency
                </title>
                <meta
                    name="description"
                    content="Want the latest in digital marketing news, trick, tips and insights? Look no further - the MITP blog is here to deliver"
                />
                <meta
                    property="og:title"
                    content="Our Blog - Thoughts, Insights & Tips | MITP Agency"
                />
                <meta
                    property="og:image"
                    content="https://www.madeinthepile.com/sharing.jpg"
                />
                <meta name="twitter:card" content="summary_large_image" />
                <meta
                    name="twitter:image"
                    content="https://www.madeinthepile.com/sharing.jpg"
                />
                <meta property="og:url" content={location.href} />
                <meta
                    property="og:description"
                    content="Want the latest in digital marketing news, trick, tips and insights? Look no further - the MITP blog is here to deliver"
                />
                <meta property="og:type" content="page" />
            </Helmet>
            <div
                className="w-full min-h-screen"
                style={{ backgroundColor: "#f9f5ed" }}
            >
                <BlogTop />
                <BlogFilter
                    tags={tags}
                    activeTag={activeTag}
                    setActiveTag={setActiveTag}
                />
                <BlogGrid blogs={blogs} tags={tags} activeTag={activeTag} />
            </div>
            <div className="hidden gradient-bg-bottom-blog" />
            <NewsletterSubscribe
                newsletterOpen={newsletterOpen}
                setNewsletterOpen={setNewsletterOpen}
            />
        </Layout>
    );
};

/* Blog Top
============================================================================= */

const BlogTop = () => {
    // Config

    // Render
    return (
        <div className="w-screen">
            <div className="container px-4 pt-32 pb-12 mx-auto md:pt-44 md:pb-0 md:px-0">
                <h2 className="text-5xl blog-top-title md:text-6xl">
                    THOUGHTS
                </h2>
                <p className="w-full pt-4 text-lg leading-relaxed blog-top-copy md:w-2/5">
                    Step inside the mind of MITP.
                </p>
                <p className="w-full pt-3 text-lg leading-relaxed blog-top-copy md:w-2/5">
                    The meeting point of our shared experience and knowledge,
                    written by those who know best.
                </p>
                <p className="w-full pt-3 text-lg leading-relaxed blog-top-copy md:w-2/5">
                    Click below to explore the future of Social, Video, AR, Web
                    & Design as it happens.
                </p>
            </div>
        </div>
    );
};

/* Blog Filter
============================================================================= */

const BlogFilter = ({ tags, activeTag, setActiveTag }) => {
    // Config

    // Render Tags
    const renderTags = () => {
        return tags.map((tag) => {
            return (
                <button
                    key={tag.tag}
                    className={`px-8 uppercase pt-4 filter-button`}
                    onClick={() => setActiveTag(tag.tag)}
                >
                    <span
                        className={`${
                            activeTag === tag.tag ? "tag-active" : ""
                        }`}
                    >
                        {tag.tag.toUpperCase()}
                    </span>
                </button>
            );
        });
    };

    // Render
    return (
        <div
            id="blog-filter-container"
            className="container hidden w-full grid-cols-7 gap-4 pt-4 pb-8 pb-32 mx-auto mt-10 text-sm md:grid font-display"
        >
            <BlogFilterButton
                activeTag={activeTag}
                setActiveTag={setActiveTag}
            />
            <BlogFilterButton
                label="AGENCY"
                backgroundColor="#C69AF4"
                activeTag={activeTag}
                setActiveTag={setActiveTag}
            />
            <BlogFilterButton
                label="AR"
                backgroundColor="#C94F6F"
                activeTag={activeTag}
                setActiveTag={setActiveTag}
            />
            <BlogFilterButton
                label="VIDEO"
                backgroundColor="#FF5B22"
                activeTag={activeTag}
                setActiveTag={setActiveTag}
            />
            <BlogFilterButton
                label="SOCIAL"
                backgroundColor="#48B0CC"
                activeTag={activeTag}
                setActiveTag={setActiveTag}
            />
            <BlogFilterButton
                label="DESIGN"
                backgroundColor="#1BB295"
                activeTag={activeTag}
                setActiveTag={setActiveTag}
            />

            <BlogFilterButton
                label="WEB"
                backgroundColor="#D86CBF"
                activeTag={activeTag}
                setActiveTag={setActiveTag}
            />
        </div>
    );
};

/* Blog Filter Button
============================================================================= */

const BlogFilterButton = ({
    label = "ALL",
    backgroundColor = "#D3C846",
    activeTag,
    setActiveTag,
}) => {
    // Config

    // Render
    return (
        <div
            onClick={() => setActiveTag(label)}
            className="relative w-full py-2 cursor-pointer"
            id="filter-button"
        >
            <div
                className="absolute top-0 left-0 z-0 w-full h-full background-fill"
                style={{
                    backgroundColor,
                    opacity: `${activeTag.toUpperCase() === label ? "1" : "0"}`,
                    borderWidth: "2px",
                    borderColor: `${
                        activeTag.toUpperCase() === label
                            ? backgroundColor
                            : "rgba(0,0,0,0)"
                    }`,
                }}
            />
            <h4 className="relative filter-text z-1">{label}</h4>
        </div>
    );
};

/* Blog Grid
============================================================================= */

const BlogGrid = ({ blogs, activeTag }) => {
    // Config

    // Render Blog Items
    const renderBlogItems = () => {
        return _.filter(blogs, (blog) => {
            if (activeTag === "ALL") {
                return true;
            }
            if (
                _.find(blog.tags, (tag) => {
                    try {
                        return (
                            tag.tag.toUpperCase() === activeTag.toUpperCase()
                        );
                    } catch (error) {
                        return false;
                    }
                })
            ) {
                return true;
            }
        }).map((blog, i) => {
            const color = mapTagToColor(blog.tags[0].tag);
            if ((i + 1) % 3 === 0) {
                return <BlogItem key={i} blog={blog} color={color} />;
            }
            if (blog.featured) {
                return <FeaturedBlogItem key={i} blog={blog} color={color} />;
            }
            return <BlogItem key={i} blog={blog} color={color} />;
        });
    };

    // Render
    return (
        <div
            id="blog-grid"
            className="container grid w-full grid-cols-1 gap-12 px-5 mx-auto lg:grid-cols-3 md:grid-cols-2 md:px-0 pb-120"
        >
            {renderBlogItems()}
        </div>
    );
};

/* Blog Item
============================================================================= */

const BlogItem = ({ blog, color }) => {
    // Config

    // Render
    return (
        <Link
            to={`/blog/${convertToKebabCase(blog.title)}/`}
            className="w-full blog-item"
        >
            <div className="relative w-full overflow-hidden h-52">
                <Image
                    fluid={blog.blogImage.asset.localFile.childImageSharp.fluid}
                    className="w-full h-52"
                />
            </div>
            <div className="relative flex flex-col items-start justify-between w-full pr-12 overflow-hidden h-44">
                <h3 className="pt-3 text-lg leading-tight blog-title">
                    {blog.title}
                </h3>
                <h4 className="leading-tight blog-subtitle" style={{ color }}>
                    {blog.subtitle}
                </h4>
                <div className="flex flex-row items-center justify-between w-full">
                    <h5 className="pt-1 text-xs leading-relaxed blog-date font-body">
                        {moment(blog._createdAt).format("MMM D, YYYY")}
                    </h5>
                    <h5 className="pt-1 text-xs leading-relaxed blog-date">
                        By {blog.blog_author.author_name}
                    </h5>
                </div>
                <div
                    className="flex flex-row items-center justify-end h-6 pr-2 mr-6 blog-category w-44"
                    style={{ backgroundColor: color }}
                >
                    <h4 className="leading-tight">{blog.tags[0].tag}</h4>
                </div>
            </div>
        </Link>
    );
};

const convertToKebabCase = (string) => {
    return string
        .replace(/\s+/g, "-")
        .toLowerCase()
        .replace(/[^a-zA-Z0-9-_]/g, "");
};

const mapTagToColor = (tag) => {
    switch (tag) {
        case "Design":
            return "#1BB295";
        case "Agency":
            return "#C69AF4";
        case "Web":
            return "#D86CBF";
        case "Social":
            return "#48B0CC";
        case "AR":
            return "#C94F6F";
        default:
            return "#FF5B22";
    }
};

/* Featured Blog Item
============================================================================= */

const FeaturedBlogItem = ({ blog, color }) => {
    // Config

    // Render
    return (
        <>
            <Link
                id="featured-blog-item"
                to={`/blog/${convertToKebabCase(blog.title)}/`}
                className="flex-row items-center justify-between hidden w-full md:flex h-96"
            >
                <div
                    className="flex flex-col items-start justify-between w-1/2 h-full px-5 pt-4 pb-4"
                    style={{ backgroundColor: color }}
                >
                    <div>
                        <h3 className="pb-2 text-4xl leading-none featured-blog-title">
                            {blog.title}
                        </h3>
                        <h4
                            className="pt-3 text-2xl leading-tight blog-subtitle"
                            style={{ color: "white" }}
                        >
                            {blog.subtitle}
                        </h4>
                    </div>
                    <div className="flex flex-row items-center justify-between w-full">
                        <h5 className="pt-1 text-xs leading-relaxed blog-date font-body">
                            {moment(blog._createdAt).format("MMM D, YYYY")}
                        </h5>
                        <h5 className="pt-1 text-xs leading-relaxed blog-date font-body">
                            By {blog.blog_author.author_name}
                        </h5>
                    </div>
                </div>
                <div className="relative w-1/2 h-full overflow-hidden">
                    <Image
                        fluid={
                            blog.blogImage.asset.localFile.childImageSharp.fluid
                        }
                        className="w-full h-full"
                    />
                    <div
                        className="flex flex-row items-center justify-end h-6 pr-2 mr-6 featured-blog-category w-112"
                        style={{
                            backgroundColor: mapTagToColor(blog.tags[0].tag),
                        }}
                    >
                        <h4
                            className="leading-tight"
                            style={{ color: "white " }}
                        >
                            {blog.tags[0].tag}
                        </h4>
                    </div>
                </div>
            </Link>
            <Link
                id="blog-item"
                to={`/blog/${convertToKebabCase(blog.title)}/`}
                className="w-full md:hidden"
            >
                <div className="relative w-full overflow-hidden h-52">
                    <Image
                        fluid={
                            blog.blogImage.asset.localFile.childImageSharp.fluid
                        }
                        className="w-full h-52"
                    />
                </div>
                <div className="relative flex flex-col items-start justify-between w-full pr-12 h-44">
                    <h3 className="pt-3 text-lg leading-tight blog-title">
                        {blog.title}
                    </h3>
                    <h4
                        className="leading-tight blog-subtitle"
                        style={{ color }}
                    >
                        {blog.subtitle}
                    </h4>
                    <div className="flex flex-row items-center justify-between w-full">
                        <h5 className="pt-1 text-xs leading-relaxed blog-date font-body">
                            {moment(blog._createdAt).format("MMM D, YYYY")}
                        </h5>
                        <h5 className="pt-1 text-xs leading-relaxed blog-date font-body">
                            By {blog.blog_author.author_name}
                        </h5>
                    </div>
                    <div
                        className="flex flex-row items-center justify-end h-6 pr-2 mr-6 blog-category w-44"
                        style={{ backgroundColor: color }}
                    >
                        <h4 className="leading-tight">{blog.tags[0].tag}</h4>
                    </div>
                </div>
            </Link>
        </>
    );
};

/* Newsletter Subscribe
============================================================================= */

const NewsletterSubscribe = () => {
    // Config
    const [email, setEmail] = useState("");
    const { setNewsletterOpen } = useNewsletter();

    const onSubmit = async () => {
        await axios.post("./.netlify/functions/newsletter", {
            email,
        });
        setEmail("");
        alert("Subscribed!");
    };

    // Render
    return (
        <>
            <div
                id="newsletter-slider"
                className="fixed hidden w-screen h-48 md:block"
            >
                <div id="newsletter-open" className="w-screen h-16">
                    <div className="container flex flex-row items-center justify-start mx-auto">
                        <h2
                            className="py-2 text-3xl"
                            style={{ fontFamily: "Kritik-Bold" }}
                        >
                            SUBSCRIBE TO OUR NEWSLETTER
                        </h2>
                    </div>
                </div>
                <div className="w-screen h-32">
                    <div className="container flex flex-col items-start justify-between w-full h-full pb-6 mx-auto">
                        <p className="w-4/5 pb-2 text-base slide-subheading lg:text-lg">
                            We won't annoy you too much, only once a month in
                            fact!
                        </p>
                        <div className="flex flex-row items-end justify-start w-full">
                            <input
                                className="text-black newsletter-input"
                                placeholder="Email Address"
                                type="email"
                                style={{ color: "black" }}
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <button
                                onClick={onSubmit}
                                className="px-8 py-2 ml-8 text-xl"
                                style={{
                                    color: "white",
                                    backgroundColor: "black",
                                    fontFamily: "Montserrat-Bold",
                                }}
                            >
                                SUBMIT
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="fixed bottom-0 w-screen h-12 bg-black md:hidden"
                style={{ zIndex: 1000 }}
                onClick={() => {
                    setNewsletterOpen(true);
                }}
            >
                <div className="container flex flex-row items-center justify-start h-12 mx-auto">
                    <h2
                        className="w-full pl-4 text-lg text-left text-white"
                        style={{ fontFamily: "Kritik-Bold", fontSize: "4vw" }}
                    >
                        SUBSCRIBE TO OUR NEWSLETTER
                    </h2>
                </div>
            </div>
        </>
    );
};

/* Page Query
============================================================================= */

export const pageQuery = graphql`
    query BlogPage {
        allSanityBlog(sort: { order: DESC, fields: _createdAt }) {
            nodes {
                blog_author {
                    author_bio {
                        children {
                            text
                        }
                    }
                    author_name
                }
                title
                subtitle
                _rawContent
                tags {
                    tag
                }
                _createdAt
                featured
                blogImage {
                    asset {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 750) {
                                    ...GatsbyImageSharpFluid_noBase64
                                }
                            }
                        }
                    }
                }
            }
        }
        allSanityTag {
            nodes {
                tag
            }
        }
    }
`;

/* Export
============================================================================= */

export default BlogPage;
