import create from "zustand";

/* Use  Blog Filter
============================================================================= */

const [useBlogFilter] = create((set) => ({
    activeTag: "ALL",
    setActiveTag: (filter) => set((state) => ({ activeTag: filter })),
}));

/* Export
============================================================================= */

export default useBlogFilter;
